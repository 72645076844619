<template>

    <transition name="fade">

        <div v-show="!videoIsPlaying[product.slug]" class="product-video-button" @click="toggleVideo(product.slug, $refs[product.slug])">
            <img src="../../images/play_button.png" alt="Play">
        </div>
        </transition>

    <transition name="fade">
         <div v-show="videoIsPlaying[product.slug]" class="product-video-button" @click="toggleVideo(product.slug, $refs[product.slug])">
            <img src="../../images/pause_button.png" alt="Stop">
        </div>

    </transition>

    <!--div class="product-display" :class="{ 'regular': size == 'regular', 'small': size == 'small' }"-->
    <div class="product-display">
        <div class="row">
            <!--div :class="{ 'gr-24': size == 'regular', 'gr-18 push-4': size == 'small' }"-->
            <div class="gr-24">
                <div class="product-display-video gr-17 gr-20@small push-7 push-4@small" :class="{ 'to-front': videoIsPlaying[product.slug], 'to-back': videoIsPlaying[product.slug] == false}">
                    <vimeo-player :ref="product.slug" :video-id="product.introduction" controls=false @playing="setVideoLoading(false, product.slug)" @pause="setVideoLoading(false, product.slug)" @ended="toggleVideo(product.slug, $refs[product.slug])"></vimeo-player>

                    <div class="loading-page" v-if="videoIsLoading[product.slug]">
                        <base-spinner class="spinner"></base-spinner>
                    </div>
                </div>

                <transition name="fade">
                    <div v-show="!videoIsPlaying[product.slug]" class="product-display-video-button-tablet" @click="toggleVideo(product.slug, $refs[product.slug])">
                        <img src="../../images/play_button.png" alt="Play">
                    </div>
                </transition>

                <transition name="fade">
                    <div v-show="videoIsPlaying[product.slug]" class="product-display-video-button-tablet" @click="toggleVideo(product.slug, $refs[product.slug])">
                        <img src="../../images/pause_button.png" alt="Stop">
                    </div>
                </transition>

                <div class="product-display-data gr-16 gr-24@small">
                    <div class="product-display-data-title">
                        <h1 class="push-1 push-0@small">{{ product.title }}</h1>
                    </div>

                    <p class="product-display-data-info gr-18 gr-15@small gr-22@mobile push-1 push-0@small">
                        <!--p class="product-display-data-info-category">{{ product.category }}</p>
                        <h2 class="product-display-data-info-coach">mit <span class="coach">{{ product.coach }}</span></h2-->
                        {{ product.specification }}
                    </p>
                </div>
            </div>

        </div>
    </div>

    <div class="product-details">
        <div class="row">
            <!--div :class="{ 'gr-24': size == 'regular', 'gr-18 push-4': size == 'small' }"-->
            <div class="gr-24">
                <div class="product-details-container-main gr-15 gr-18@small gr-19@tablet gr-22@mobile push-3 push-2@small push-1@mobile">
                    <div class="product-details-container-main-content">
                        <div>
                            <h3 class="gr-15 gr-21small gr-22@mini push-1">{{ product.descriptionLabel }}</h3>
                            <p class="gr-15 gr-21@small gr-22@mini push-1">{{ product.descriptionText }}</p>
                        </div>
                        <a v-if="page =='orderPage'" href="#signup" class="gr-13 gr-18@small gr-24@mobile gr-22@mini push-1 push-1@mobile"><base-button class="button home-button product-details-container-main-content-button" :style="{ display: displaySignupForm? 'block' : 'none'}">{{ buttonText }}</base-button></a>
                        <base-button v-else @click="openProductPage(product.slug)" class="product-details-container-main-content-button button gr-15 gr-21@small gr-22@mobile push-1 push-0@mobile">{{ buttonText }}</base-button>
                    </div>
                </div>

                <div class="product-details-container-side gr-8 gr-14@small gr-16@tablet gr-22@mobile push-8@small push-7@tablet push-1@mobile">
                    <div class="product-details-container-side-content gr-20 gr-22@small push-2 push-1@small">
                        <h3>Benefits</h3>
                        <ul>
                            <li v-for="(goal, index) in product.benefits" :key="index">
                                {{ goal }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        props: ['page', 'size', 'product', 'buttonText', 'displaySignupForm', 'videoIsLoading', 'videoIsPlaying'],
        inject: ['setVideoLoading', 'toggleVideo'],
        data() {
            return {
            }
        },
        methods: {
            openProductPage(slug) {
                this.$router.push({ name: 'course', params: { slug: slug }})
            }
        },
    }
</script>


<style scoped>
</style>
